/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Event type"), React.createElement(_components.th, {
    align: "left"
  }, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.code, null, "processingAccount.status.changed")), React.createElement(_components.td, {
    align: "left"
  }, "Status of the processing account has changed.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.code, null, "processingAccount.riskStatus.changed")), React.createElement(_components.td, {
    align: "left"
  }, "Risk status of the processing account has changed."))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
